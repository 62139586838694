@font-face {
  font-family: "Troll";
  src: url("./troll-12-src.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-size: 12;
}

body {
  margin: 0;
  background-color: #ffffff;  
}

