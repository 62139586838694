.app {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: "Troll";
}

.app-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.gh-logo {
  mask-image: url("./gh-logo-plain.svg");
  background-color: #000;
  color: #248;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 16vw;
  aspect-ratio: 1;
  margin-left: -5%;
  margin-top: -5%;
}

h1 {
  font-size: 7vw;
  display: flex;
  flex-direction: column;
  align-items: start;
  line-height: 6vw;
  margin: 0;
}

h1 .llc-text {
  font-size: 0.5em;
}

.about {
  font-size: 3vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: left;
}

.about p {
  max-width: 75vw;
}

/* Portrait */
@media only screen 
  and (min-device-width: 375px) 
  and (max-device-width: 1000px) 
  and (orientation: portrait) { 
  .app-header {
    flex-direction: column;
  }
  .gh-logo {
    width: 33vh;
    margin-left: 0;
  }
  h1 {
    font-size: 10vw;
    line-height: 8vw;
    margin-top: 0.5em;
  }
  .about {
    font-size: 18pt;
  }
}

/* Landscape */
@media only screen 
  and (min-device-width: 375px) 
  and (max-device-width: 1000px) 
  and (orientation: landscape) { 
  .app {
    flex-direction: row;
    gap: 10vw;
  }
  .app-header {
    flex-direction: column;
    position: relative;
  }
  h1 {
    text-align: center;
    align-items: center;
    justify-content: start;
  }
  h1 .llc-text {
    text-align: center;
  }
  .gh-logo {
    margin: 0;
    width: 30vh;
  }
  .about {
    width: 47.5%;
  }
}
